<template>
    <div class="input-group color-picker" ref="colorpicker" style="width: 35px">
        <span class="current-color" :style="'background-color: ' + colorValue" @click="togglePicker()"></span>
        <sketch-picker :preset-colors="presetColors" :value="colors" @input="updateFromPicker" v-if="displayPicker" :style="spStyle" />
        <!--</span>-->
    </div>
</template>

<script>
    import { Sketch } from 'vue-color'
    export default {
        model: {
            prop: 'color'
        },
        props: {
            color: {
                type: String,
                default: '#000000'
            }
        },
        data() {
            return {
                colors: {
                    hex: '#000000',
                },
                colorValue: '',
                displayPicker: false,
            }
        },
        computed: {
            presetColors() {
                return this.$store.getters.presetColors
            },
            spStyle() {
                let el = this.$refs.colorpicker
                console.log('left: ' + el.getBoundingClientRect().left)
                console.log('bottom: ' + el.getBoundingClientRect().bottom)
                console.log('width: ' + window.innerHeight)
                let right = '0px'
                let top = '35px'
                if (el.getBoundingClientRect().right < window.innerWidth / 2) right = '-180px'
                if (window.innerHeight - el.getBoundingClientRect().bottom < 350) top = '-320px'
                return {
                    'right': right,
                    'top': top
                }
            }
        },
        mounted() {
            this.setColor(this.color || '#000000');
        },
        methods: {
            setColor(color) {
                this.updateColors(color);
                this.colorValue = color;
            },
            updateColors(color) {
                if (color.slice(0, 1) == '#') {
                    this.colors = {
                        hex: color
                    };
                }
                else if (color.slice(0, 4) == 'rgba') {
                    var rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(','),
                        hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1);
                    this.colors = {
                        hex: hex,
                        a: rgba[3],
                    }
                }
            },
            showPicker() {
                document.addEventListener('click', this.documentClick);
                this.displayPicker = true;
            },
            hidePicker() {
                document.removeEventListener('click', this.documentClick);
                this.$store.commit('updatePresetColors', this.colorValue)
                this.displayPicker = false;
            },
            togglePicker() {
                this.displayPicker ? this.hidePicker() : this.showPicker();
            },
            updateFromPicker(color) {
                this.colors = color;
                this.colorValue = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')';
            },
            documentClick(e) {
                let el = this.$refs.colorpicker,
                    target = e.target;
                if (el !== target && !el.contains(target)) {
                    this.hidePicker()
                }
            }
        },
        watch: {
            colorValue(val) {
                if (val) {
                    //this.updateColors(val);
                    this.$emit('input', val);
                    //document.body.style.background = val;
                }
            },
            color(val) {
                if (val) {
                    this.setColor(val);
                }
            }
        },
        components: {
            'sketch-picker': Sketch
        }
    }
</script>

<style scoped>
    .vc-sketch {
        position: absolute;
        z-index: 9;
    }
    .current-color {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 3px;
        border-color: #9B9B9B;
        border-style: solid;
        border-width: thin;
        background-color: #000;
        cursor: pointer;
        vertical-align: middle;
    }
</style>